import React, {useState, useEffect} from 'react'
import {Container, Row, Col, Tooltip} from 'reactstrap';
import Button from '@material-ui/core/Button';

import PaymentIcon from '@material-ui/icons/Payment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';



import './PaymentMethod.css'

function PaymentMethod({paymentMethod, setPaymentMethod}) {

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const [cardButton, setCardButton] = useState(true);

    useEffect(() => {

        paymentMethod === "card"

        ?

        setCardButton(true)

        :

        setCardButton(false);

    }, [paymentMethod])

    return (
        <div className="pm-container">
            <Container>
                <Row>
                    <Col>
                        {
                            cardButton ?

                            <Button onClick={() => setPaymentMethod("card")} className="button-active"><PaymentIcon />Pay with card</Button>

                            :

                            <Button onClick={() => setPaymentMethod("card")} className="button"><PaymentIcon />Pay with card</Button>

                        }
                    </Col>
                    <Col>
                        {
                            cardButton ?
                                
                                <Button onClick={() => setPaymentMethod("bank")} id="payByBank" className="button"><AccountBalanceIcon />Pay by bank</Button>

                            :

                                <Button onClick={() => setPaymentMethod("bank")} id="payByBank" className="button-active"><AccountBalanceIcon />Pay by bank</Button>
                            
                        }
                        <Tooltip placement="right" isOpen={tooltipOpen} target="payByBank" toggle={toggle}>
                           Pay with your bank and earn special rewards.
                        </Tooltip>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PaymentMethod
