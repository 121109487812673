import React, {useState, useContext, useEffect} from 'react'

import ImpactSection from '../impact/ImpactSection';
import AuthLayout from '../authentication/AuthLayout.js';

import {Container, Row, Col} from 'reactstrap';

import axios from 'axios';
import Cookies from 'js-cookie';
import url from '../../api/url'; 

import SocketId from '../../context/SocketId';

import socketIOClient from "socket.io-client";

import CheckoutContext from '../../context/CheckoutContext';
import UserContext from '../../context/UserContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import PaymentMethod from '../Payment Method/PaymentMethod';
import PayBank from '../Pay Bank/payBank';
import SelectBank from '../select bank/SelectBank';

import ConfirmLayout from '../confirm/ConfirmLayout';


import BankContext from '../../context/BankContext';
import ScanQRLottie from './ScanQRLottie';

import {Button} from '@material-ui/core'

const CheckoutPage = ({isAuthenticated, setIsAuthenticated}) => {

    const {socketId, setSocketId} = useContext(SocketId);

  
  const [spinner, setSpinner] = useState(false);

  const [kgo2,setKgco2] = useState(0);
  const [carbonTreeRatio,setCarbonTreeRatio] = useState(0);
  
  const [selectBank, setSelectedBank] = useState(true);

  const [merchantLoader, setMerchantLoader] = useState(false);

  const {obData, setObData} = useContext(BankContext);

const [paymentMethod, setPaymentMethod] = useState(localStorage.getItem("paymentMethod") ? localStorage.getItem("paymentMethod") : "wallet")
  const [confirmPayment, setConfirmPayment] = useState(false);
  const [paymentSessionId, setPaymentSessionId] = useState("");

      const [checkoutData, setCheckoutData] = useState({
        price: 0
    })

  const [explainer, setExplainer] = useState(false);
  const [paymentMethodId, setPaymentMethodId] = useState("");


  useEffect(() => {


    if(obData.set) {

      setExplainer(true)

    }

  }, [obData])

  useEffect(() => {
    
    const timer = setTimeout(() => {
      setExplainer(false)
    }, 10000);

    return () => clearTimeout(timer);
    
  }, [explainer])

  const [impactSection, setImpactSection] = useState(false);

  // Fetch store and create active session

  useEffect(() => {

    let store_id = (new URL(window.location.href)).searchParams.get('store_id')

    const CreatePaymentIntent = async() => {

      await axios.post(`${url}/payment/direct/create-payment`, {}, {
        headers: {
          'x-auth-token': Cookies.get('token')
        }
      })

    }

  }, [])


  // Socket


    useEffect(() => {
        
        const socket = socketIOClient(`${url}`, {
            path: "/payment/socket",
            withCredentials: true,
            transportOptions: {
            polling: {
                extraHeaders: {
                    "my-custom-header": "abcd"
                }
            }
        }
    });

    socket.on('connect', () => {
        setSocketId(socket.id)
    })

      socket.on('message', (data) => {

        

        if(data.amount) {
          setCheckoutData({
            price: data.amount,
            donation: data.donation_amount,
            store_name: data.store_name
          })
          setConfirmPayment(true)

          setKgco2(data.kg_co2)
          setCarbonTreeRatio(data.co2_tree_ratio)
        }

      
      }) 
    }, [])


        useEffect(() => {

    const storeSocketId = async() => {
      await axios.post(`${url}/customer/socket/data`, {
          
            socket_id: socketId
      }, {
        headers: {
          'x-auth-token': Cookies.get("token")
        }
      }
      ).then()
        .catch(err => console.log(err.response))
    }

    if(socketId) {
      storeSocketId() 
    } else {
      return;
    }
  

  }, [socketId])

      let online = (new URL(window.location.href)).searchParams.get('online');
      let amount = (new URL(window.location.href)).searchParams.get('amount');
      let store_name = (new URL(window.location.href)).searchParams.get('store_name');

      let donation_amount = (new URL(window.location.href)).searchParams.get('donation_amount');

  useEffect(() => {
    if(online) {

      setCheckoutData({
            price: amount,
            donation: donation_amount,
            store_name: store_name

          })

    } else {
      return;
    }
  }, [])

 
    return (
      <>

      {

        spinner ?

        <CircularProgress className="page-loading-spinner" />

        :


        
        <>

        <BankContext.Provider value={{obData, setObData}}>
        

   
          <div className="payment-container">

          {

            confirmPayment ?

              <ConfirmLayout paymentMethodId={paymentMethodId} setPaymentMethodId={setPaymentMethodId} paymentMethod={paymentMethod} checkoutData={checkoutData} paymentSessionId={paymentSessionId} setPaymentSessionId={setPaymentSessionId} confirmPayment={confirmPayment} setConfirmPayment={setConfirmPayment} setMerchantLoader={setMerchantLoader} setImpactSection={setImpactSection} setConfirmPayment={setConfirmPayment}/>

            :
            <>

              {
                !obData.set ?


                null

          :


                      <div className="impact-section">
              <div className="non-mobile-impact">
{
                explainer ?
                <div>
                <ScanQRLottie />
                <Button onClick={() => setExplainer(false)}>Skip</Button>
                </div>
                :

            
                 impactSection ?
                  <ImpactSection paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} kgCo2={kgo2} carbonTreeRatio={carbonTreeRatio}/> 
                  :
                  null
     
                }
              </div>
              <div className="mobile-impact">
                  {
                    merchantLoader ?

                    null

                    :

                    <ImpactSection  paymentMethodId = {paymentMethodId} setPaymentMethodId = {setPaymentMethodId} paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} merchantLoader={merchantLoader} setMerchantLoader={setMerchantLoader} kgCo2={kgo2} carbonTreeRatio={carbonTreeRatio}/>
                  }
              </div>
          </div>
              }

          <div  className="payment-section">
            
            
   
                    {/* <SelectBank  setImpactSection={setImpactSection}/> */}
                {

                    !obData.set ?

                    <PayBank paymentMethodId={paymentMethodId} paymentMethod={paymentMethod} paymentSessionId={paymentSessionId} setPaymentSessionId={setPaymentSessionId} confirmPayment={confirmPayment} setConfirmPayment={setConfirmPayment} merchantLoader={merchantLoader} setMerchantLoader={setMerchantLoader} setImpactSection={setImpactSection}/>


                    :

                    <PayBank paymentMethodId={paymentMethodId} paymentMethod={paymentMethod} paymentSessionId={paymentSessionId} setPaymentSessionId={setPaymentSessionId} confirmPayment={confirmPayment} setConfirmPayment={setConfirmPayment} merchantLoader={merchantLoader} setMerchantLoader={setMerchantLoader} setImpactSection={setImpactSection}/>

                  


                }
          
                
                   
   
         
           
          </div>
              </>

          }
          

            
        </div>

        </BankContext.Provider>
      
        </>
        
      }
      </>
    )
}

const styles={
  container: {
    display: 'flex', 
    flexDirection: 'row', 
    width: '100%', 
    justifyContent: 'center', 
    alignItems: 'center'
  },
  ImpactSection:{
    display:"none"
  },
  explainerContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '450px',
    minHeight: '312px',
    height: '100%',
    backgroundColor: '#3d978b',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}

export default CheckoutPage
