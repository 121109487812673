import React, {useState, useContext, useEffect} from 'react';
import { Button, Container, Row, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import LoginForm from './Login';
import SignupForm from './Signup';

import UserContext from '../../context/UserContext';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import VerifyCode from './VerifyCode';

import CircularProgress from '@material-ui/core/CircularProgress';
import EnterPassword from './EnterPassword';

import axios from 'axios';
import Cookies from 'js-cookie'
import url from '../../api/url'

const AuthLayout = ({isAuthenticated, setIsAuthenticated}) => {
  

  const [authMethod, setAuthMethod] = useState('Login');
  const {userData, setUserData} = useContext(UserContext);

  const [step, setStep] = useState(0);

  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");

  const [userState, setUserState] = useState("");

  const [spinner, setSpinner] = useState(false);

  const [verifyCode, setVerifyCode] = useState(false);

  let price = (new URL(window.location.href)).searchParams.get('amount');



  useEffect(() => {


    if(userState.new_user) {

      // partially sign up

      setStep(1)

    } else if(userState.partial_user) {

      setStep(2)

    } else if(userState.full_user){

      setStep(3)

    } else {

      setStep(0)

    }

  }, [userState])


      // decrement step function

    const decrementStep = () => {

      if(step===0) {
        setStep(0)
      } else {
        setStep(step-1)
      }

    }


    const incrementStep = () => {

      if(step===4) {
        setStep(3)
      } else {
        setStep(step+1)
      }
    }

    



      const wizardStep = [
        {
            step: 0,
            component: <LoginForm setUserState={setUserState} step={step} setStep={setStep} setVerifyCode={setVerifyCode} phone={phone} setPhone={setPhone} firstName={firstName} setFirstName={setFirstName}/>,
            title: "Thanks for picking the eco way to pay",
            subtitle: ""
        },
         {
            step: 1,
            component: <VerifyCode setUserState={setUserState} incrementStep={incrementStep} setIsAuthenticated={setIsAuthenticated} step={step} setStep={setStep} phone={phone} setPhone={setPhone} setVerifyCode={setVerifyCode}/>,
            title: "Verify",
            subtitle: `Please enter the code we just sent to +${phone}`
        },
         {
            step: 2,
            component: <SignupForm setUserState={setUserState} step={step} setStep={setStep} setVerifyCode={setVerifyCode} phone={phone} setPhone={setPhone} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} setAuthMethod = {setAuthMethod}/>,
            title: "Complete Sign up",
            subtitle: `Welcome back ${firstName}, we just need a few more details!`
        },
         {
            step: 3,
            component: <EnterPassword setUserState={setUserState} step={step} setStep={setStep} setVerifyCode={setVerifyCode} phone={phone} setPhone={setPhone} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} setAuthMethod = {setAuthMethod}/>,
            title: "Login",
            subtitle: `Welcome back ${firstName}!`
        },
    ]




    // INPUT VALIDATION

    useEffect(() => {

      if(phone.substring(0, 1) === "0") {
        setPhone(phone.substring(1, ))
      }

    }, [phone])



      



  return (
    <div className="auth-container">
        <div className="auth-form impact-section">
        <Container>
        <Row>
          <div style={{textAlign: "right", width: "100%"}}>
            <div className="space-between">
            <span onClick={() => {setStep(0)}} style={{fontSize: '14px', cursor: "pointer"}}>
                {
                  step === 0 ? null : <KeyboardBackspaceIcon /> 
                }
              </span>
              <span style={{fontSize: '14px'}}>
                <ShoppingCartIcon /> 
              </span>
            </div>
          </div>
        </Row>
   

       
          
  
            <Row>  

               <div className="form-header">
            {/* {
              verifyCode ? */}

              <div style={{display: "inline"}}>
                <h2 style={{margin: "10px auto"}}>{wizardStep[step].title}</h2>
                <small>{wizardStep[step].subtitle}</small>
            </div>

             

            {/* } */}
          </div>


              {wizardStep[step].component}
            
          
            </Row>

        </Container>
        </div>
    </div>
  );
}

export default AuthLayout;
