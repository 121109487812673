import React, {useContext, useState, useEffect} from 'react'
import {QRCode} from "react-qrcode-logo";
import ReactQR from 'react-awesome-qr';

import {Container, Row, Col, Tooltip, Spinner} from 'reactstrap';
import Button from '@material-ui/core/Button';

import { ToastContainer, toast } from 'react-toastify';


import './PayBank.css';

import Cookies from 'js-cookie';
import axios from 'axios';
import url from '../../api/url';

import QRContext from '../../context/QRContext';
import BankContext from '../../context/BankContext';

import logo from '../../Images/Dark-01 copy.png'


import CircularProgress from '@material-ui/core/CircularProgress';
import { red } from '@material-ui/core/colors';
import MerchantLoading from '../confirm/MerchantLoading';

import ConfirmLayout from '../confirm/ConfirmLayout.js';
import { Redirect } from 'react-router-dom';



const PayBank = ({paymentSessionId, paymentMethodId, paymentMethod, setPaymentSessionId, confirmPayment, setConfirmPayment, merchantLoader, setMerchantLoader}) => {

    const {QR, setQR} = useContext(QRContext);
    const {obData} = useContext(BankContext);

    const [spinner, setSpinner] = useState(false);
    const [QRActive, setQRActive]= useState(false);

    const [QRComplete, setQRComplete] = useState(false);

    const [redirectUrl, setRedirectUrl] = useState('');

    const [spinnerMessage, setSpinnerMessage] = useState("Please complete the payment on your phone...")

    const [runSocketSave, setSocketSave] = useState(false);

    const token = Cookies.get('token');

    var url_string = window.location.href
    var url1 = new URL(url_string);
    var payment_session_id = url1.searchParams.get("payment_session_id");

    var merchant_socket_id = url1.searchParams.get("merchant_socket_id");
    var epos = url1.searchParams.get("epos");

    const store_id=url1.searchParams.get("store_id")


    const [statusScreen, setStatusScreen] = useState(false);

    const [buttonLoader, setButtonLoader] = useState(true);
    const [onlinePayment, setOnlinePayment] = useState(false)


    let online = (new URL(window.location.href)).searchParams.get('online');
    let vendor_id = (new URL(window.location.href)).searchParams.get('vendor_id');





    //   useEffect(() => {

    //     const createPaymentIntent = async() => {

    //     await axios.post(`${url}/payment/direct/create-payment`, {
    //      payment_session_id: payment_session_id,
    //      epos: epos?epos:false,
    //      merchant_socket_id: epos?merchant_socket_id:false
    //     }, {
    //         headers: {
    //             "x-auth-token": token
    //         }
    //     }).then(res => {
    //         console.log(res.data)
    //         setSocketSave(true);
    //     }).catch(err => console.log(err.response));

    //     }

    //     createPaymentIntent();

    // }, [])

const [paymentExpired, setPaymentExpired] = useState(false)

const [tokenGenerated, setTokenGenerated] = useState(false);

    useEffect(() => {

  

    if(online) {
        setOnlinePayment(true)
        setButtonLoader(false)
    } else {
        setButtonLoader(false)
    }

    }, [])



const createPaymentIntent = async() => {

        setSpinner(true)

        await axios.post(`${url}/payment/direct/create-payment`, {
         store_id: store_id,
         payment_method: paymentMethod
        }, {
            headers: {
                "x-auth-token": token
            }
        }).then(res => {
        
          
            setPaymentSessionId(res.data.payment_session_id)

            if(paymentMethod !== "bank") {
                setMerchantLoader(true)
                setTokenGenerated(true)
            }
        }).catch(err => {console.log(err.response)
        setSpinner(false)});
            
        }




       
       const getAccessToken = async() => {
                await axios.post(`${url}/payment/direct/access-token`, {
                    payment_session_id: online ? payment_session_id : paymentSessionId
                }, {
                    headers: {
                        'x-auth-token': Cookies.get("token")
                    }
                })
                .then(res => {
                    setSpinner(false)
                    
                    if(online) {
                        setConfirmPayment(true)
                        return;
                    } else {
                        setMerchantLoader(true)
                    setTokenGenerated(true)
                    }})
                .catch(err => {
                    console.log(err.response, "error response from token generation")

                    console.log(err.response.status === 401)

                    if(err.response.status === 401) {
                        setPaymentExpired(true)
                    }
                })
            }

        useEffect(() => {

     

            if(paymentSessionId && paymentMethod==="bank") {
                getAccessToken()
            } else {
                return;
            }

        }, [paymentSessionId])


        useEffect(() => {

            const connectToStore = async() => {
                await axios.post(`${url}/payment/direct/store/connect`, 
                {
                    store_id: store_id
                }, {
                    headers: {
                        'x-auth-token': Cookies.get('token')
                    }
                })
                .then(res => {
                })
                .catch(err => {
                    console.log(err)
                })
            }

            const disconnectFromStore = async() => {
                 await axios.post(`${url}/payment/direct/store/disconnect`, 
                {
                    store_id: store_id
                }, {
                    headers: {
                        'x-auth-token': Cookies.get('token')
                    }
                })
                .then(res => {
                })
                .catch(err => {
                    console.log(err)
                })
            }

            if(tokenGenerated) {
                connectToStore()
            } else {
                disconnectFromStore()
            }

        }, [tokenGenerated])

    return (
        paymentExpired ?

        <Redirect to="/expired"/>

        :

                <div className="qr-container">
            {
                !merchantLoader && !confirmPayment ?

                <>
                            {/* <Row className="qr-row">
                <Col>
                    {
                        QRActive ?

                        <div>
                            <Row>
                                <Col>
                                    <Spinner className="qr-spinner"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <small>{spinnerMessage}</small>
                                </Col>
                            </Row>
                        </div>


                        :

                        <QRCode className="qr-style" ecLevel="M" logoImage={logo} logoWidth="40" qrStyle="dots" size="220" bgColor="#f4f6fa" fgColor="#65ccb8" value={`https://vizopay-gateway.herokuapp.com/pay/qr/?payment_session_id=${payment_session_id}&token=${token}&provider_id=${obData.bank_data.id}`} />

                    }
                </Col>
            </Row> */}
            <Row>
                <div className="button-container">
                    {/* <Button className="desktop-button" onClick={createPaymentIntent} >
                    {
                             spinner ?

                            <CircularProgress className="spinner"/>

                             :

                             "Pay in browser"

                         }
                    </Button> */}
                    {
                        buttonLoader ?

                        <Button style={{width: '80%'}} className="button-container" >
              

                            <CircularProgress className="spinner"/>

                     
                        </Button>

                        :

                        onlinePayment ?

                        <Button style={{width: '80%'}} className="button-container"  onClick={getAccessToken}>
                        {
                             spinner ?

                            <CircularProgress className="spinner"/>

                             :

                             "Next"

                         }
                    </Button>

                        :

                        <Button style={{width: '80%'}} className="button-container" onClick={createPaymentIntent} >
                    {
                             spinner ?

                            <CircularProgress className="spinner"/>

                             :

                             "Next"

                         }
                    </Button>
                    }
                </div>
            </Row>
                
         
    
                </>

                :

                confirmPayment  ?

                <ConfirmLayout paymentMethodId={paymentMethodId} paymentMethod={paymentMethod} />

                :

                <MerchantLoading /> 
            }
        </div>
    )
}

export default PayBank;
