import React, {useState, useContext, useEffect} from 'react'
import {Row} from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import ImpactSection from '../impact/ImpactSection';
import BankContext from '../../context/BankContext';

import url from '../../api/url';
import axios from 'axios'
import Cookies from 'js-cookie'
import StripeCheckout from '../../StripeCheckout';

function ConfirmLayout({paymentSessionId, paymentMethodId, setMerchantLoader, setPaymentMethodId, paymentMethod, checkoutData, setCheckoutData, setConfirmPayment, setImpactSection}) {

    var url_string = window.location.href
    var url1 = new URL(url_string);
    var payment_session_id = url1.searchParams.get("payment_session_id");

    let online = (new URL(window.location.href)).searchParams.get('online');

    const {obData} = useContext(BankContext);

    const [spinner, setSpinner ] = useState(false);
    
    const [paymentMethodType, setPaymentMethodType] = useState("card")

    const [pay, setPay] = useState(false);
    const [amount, setAmount] = useState(0);
    const [donationAmount, setDonationAmount] = useState(0)

    const [paymentInProgress, setPaymentInProgress] = useState(false);

    const [donationFee, setDonationFee] = useState(0);

    const [payURL, setPayURL] = useState("");

    const trees = ((checkoutData.donation)/10);
    const carbon = (trees*12.3).toPrecision(4)

    const initiatePayment = async() => {
        setSpinner(true);

        await axios.post(`${url}/payment/direct/initiate`, {
            payment_session_id: online ? payment_session_id : paymentSessionId,
            provider_id: obData.bank_data.id,
            online_payment: online ? true : false
        }, {
            headers: {
                'x-auth-token': Cookies.get("token")
            }
        })
        .then((res) => {
           

            setPayURL(res.data.message.auth_uri);

            setSpinner(false);
        })
        .catch(err => console.log(err.response))
    }

    const updatePayment = async() => {
        await axios.put(`${url}/payment/online/customer-payment`, {
            payment_session_id: payment_session_id
        }, {
            headers: {
                'x-auth-token': Cookies.get("token")
            }
        }).then(res => {
           
        }).catch(err => {
            console.log(err.response, "customer save to payment.")
        })
    }

    useEffect(() => {
    
        if(paymentMethod === "bank") {
            initiatePayment();
        }
        

    }, [])

    useEffect(() => {
        if(online) {
            updatePayment();
        } else {
            return;
        }
    }, [])

    let seedCredits = (Math.floor(((checkoutData.donation/10) - Math.floor(trees))/1*100))


    return (
        <div className="confirm-pay-container">

           {
               paymentInProgress ?

               <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                    <Row style={{margin: "20px auto"}}>
                        <CircularProgress style={{color: "#65ccb8"}}/>
                    </Row>
                    <Row style={{margin: "20px auto"}}>
                        <p>Please complete the payment in your banking app.</p>
                    </Row>
                    {/* <Row style={{margin: "20px auto"}}>
                         <Button onClick={() => 
                            {setConfirmPayment(false)
                            setMerchantLoader(false)

                         }} style={{backgroundColor: '#FF5C58', color: '#fff'}} className="mobile-button" >
                             Cancel
                         </Button>
                    </Row> */}
               </div>

               :

                <>
                {/* Impact section */}

                           <Row>
                <div className="list-impact-div">
                    <div className="amount-header">
                        <h1>£{(checkoutData.price/100).toFixed(2)}</h1>
                        <h6>Payment to: <span style={{fontWeight: "700"}}>{checkoutData.store_name}</span></h6>
                    </div>
                    <div>
                    <ul>
                        
                        {
                            (checkoutData.donation < 10) ? 

                        null


                            :

                            

                                Math.floor(trees) >= 1 ?

                                <li><CheckCircleIcon className="icon-check"/> Plant {Math.floor(trees)} {
                            Math.floor(trees) > 1 ? 'trees with your purchase.' : 'tree with your purchase.'
                        }</li>

                            :

                            null

                            
                        

                        }

                        {
                            seedCredits === 0  ?

                            null

                            :

                        <li><CheckCircleIcon className="icon-check"/> Earn {seedCredits} seed credits with your purchase.</li>


                        }
                        
                        <li><CheckCircleIcon className="icon-check"/>Remove up to {carbon}kg of CO<sub>2</sub> <span className="data-param">/per year</span></li>
                    </ul>
                    </div>
                </div>
            </Row>
            {
                paymentMethod === "bank" ?

                <>
                <Row style={{width: '80%'}}>
                <div className="payment-method-confirm-container">
                    <h6 style={{textAlign: 'left'}}>Payment</h6>
                    <div>
                        {/* User payment method */}

                        
                    </div>
                </div>
            </Row>
            <Row style={{padding: "20px auto", width: '80%'}}>
                <a style={{width: '100%'}} onClick={() =>  setPaymentInProgress(true)} href={payURL} target="_blank"><Button style={{backgroundColor: '#65ccb8', color: '#fff', width: '100%'}} className="mobile-button" >
                    {
                             spinner ?

                            <CircularProgress className="spinner"/>

                             :

                             `Pay £${((checkoutData.price/100).toFixed(2))}`

                         }
                    </Button></a>
            </Row>
            <div className="tl-disclaimer-container">
                    <small style={{fontSize: '10px', lineHeight: 1, fontFamily: "Avenir", fontWeight: '300'}}>By continuing you are permitting TrueLayer to initiate a payment from your bank account. You also agree to TrueLayer end-user <a href="https://truelayer.com/enduser_tos/" target="_blank">terms</a> and <a href="https://truelayer.com/privacy/" target="_blank">privacy policy.</a></small>
                </div>
                </>

                :

 
                <StripeCheckout setMerchantLoader={setMerchantLoader} setConfirmPayment={setConfirmPayment} setImpactSection={setImpactSection} paymentMethodId={paymentMethodId} setPaymentMethodId={setPaymentMethodId} checkoutData={checkoutData} paymentMethod={paymentMethod} paymentSessionId={paymentSessionId} amount={checkoutData.price}/>
            }
                </>
           }
            
        </div>
    )
}

export default ConfirmLayout
