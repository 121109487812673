import React, {useState, useEffect} from 'react'
import './new-layout.css';

import {Button} from 'reactstrap';
import PhoneNumber from './PhoneNumber';
import WelcomeTop from './WelcomeTop';
import EnterCode from './EnterCode';
import SmallTop from './SmallTop';

import {loadStripe} from '@stripe/stripe-js';
import {PaymentRequestButtonElement, useStripe} from '@stripe/react-stripe-js';


import axios from 'axios';
import Cookies from 'js-cookie';
import url from '../../api/url';
import SelectPayment from './SelectPayment';
import SelectPaymentMethod from '../impact/SelectPaymentMethod';


function NewLayout({isAuthenticated}) {



    const stripePromise = loadStripe('pk_test_51HIjc2IZ27b5E9793ry7AUgz9M6eaTxc1HCTgevsNed0TyB8zKdJzuxIX874WE56AdfjVfmKrLdewCY9t3eVSNSv00ZIlzhlWQ');




    const [loading, setLoading] = useState(false);
 

    const [activeIndex, setActiveIndex] = useState(1)
    const [firstName, setFirstName] = useState("");

    // things

    const [phone, setPhone] = useState("");


    // functions

    const decrementStep = () => {
       if(activeIndex === 0) {
            setActiveIndex(0)
       } else {
            setActiveIndex(activeIndex - 1)
       }
    }

    const incrementStep = () => {
       
            setActiveIndex(activeIndex + 1)

    }

    // Component array


    const components = [
        {
            top: <WelcomeTop firstName={firstName}/>,
            component: <PhoneNumber firstName={firstName} setFirstName={setFirstName} phone={phone} setPhone={setPhone}/>,
            button: <Button onClick={incrementStep}>Next</Button>
        },
        {
            top: <SmallTop middleText="Enter the code" rightText="" decrementStep={decrementStep}/>,
            component: <EnterCode phone={phone} />,
            button: <Button onClick={incrementStep}>Next</Button>
        },
        {
            top: <SmallTop decrementStep={decrementStep} middleText="Select payment method" rightText=""/>,
            component: <SelectPaymentMethod />,
            button: <Button onClick={incrementStep}>Next</Button>
        }
    ]

      const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);


  useEffect(() => {

    console.log("PAYMENT REQUEST: ", paymentRequest)

  }, [paymentRequest]) 

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Demo total',
          amount: 1099,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);




    return (

        <div className="nl-container">
            <div className="flex-center-container">
                {components[activeIndex].top}
            </div>

            <div className="flex-top-container">
                {components[activeIndex].component}
            </div>
            <div className="flex-center-container" >
                {components[activeIndex].button}
            </div>
 
        </div>
    
 
    )
}

export default NewLayout
