import React, {useState, useEffect,useMemo} from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@material-ui/core/TextField';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import Cookies from 'js-cookie';
import url from '../../api/url';
import {FormGroup, Row, Col} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import "./stripe.css"  
  
  const useOptions = () => {
 
  const options = useMemo(
    () => ({
      style: {
        base: {
          
          color: "#424770",
          width:"100%",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    []
  );

  return options;
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "95%",
  maxWidth: "500px",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function StripeAddCard({open, setOpen}) {
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [name,setName] = useState("");
    const [clientSecret, setClientSecret] = useState("");

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();


  useEffect(  () => {

    const getClientSecret = async() => {
        await axios.post(`${url}/customer/stripe/setup-intent`,{},
        {
          headers: {
            'x-auth-token': Cookies.get('token')
          }
        })
            .then(res => {
               setClientSecret(res.data.client_secret)
            })
            .catch(err => {
                toast.error(err.response.data.message, {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    });
            })
    }

    getClientSecret()
  },[])



  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
   const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    });

    if (result.error) {
      // Display result.error.message in your UI.
      toast.error(result.error.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        });
        console.log(result.error.message)

        handleClose();

    } else {
        toast.success("Card Added",{
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,  
        })
        handleClose();
      // The setup has succeeded. Display a success message and send
      // result.setupIntent.payment_method to your server to save the
      // card to a Customer
    }
  };

    
    const ColorButton = styled(Button)(({ theme }) => ({
        color: "white",
        backgroundColor: "#65ccb8",
        '&:hover': {
          backgroundColor: "#65ccb8",
        },
      }));


    return (
        <div>
            <ToastContainer />
           
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
            <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add a Debit Card or Credit Card
          </Typography>
        
        <form onSubmit={handleSubmit}>
     <FormGroup>
     <Typography style={{paddingLeft:"10px"}}>
            Card Details
          </Typography>
       
        <CardElement 
          options={options}
          onReady={() => {
            console.log("CardElement [ready]");
          }}
          onChange={event => {
            console.log("CardElement [change]", event);
          }}
          onBlur={() => {
            console.log("CardElement [blur]");
          }}
          onFocus={() => {
            console.log("CardElement [focus]");
          }}
        />
      </FormGroup>
      
      <ColorButton type="submit" disabled={!stripe} style={{width: "80%"}} variant="contained"  >
                    Add Card
     </ColorButton>
    </form>
     
                    
                  
        </Box>
    
      </Modal>
    </div>
    )
}

export default StripeAddCard