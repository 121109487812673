import React, {useState, useContext, useEffect} from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import url from '../../api/url';
import axios from 'axios';
import Cookies from 'js-cookie';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'


import UserContext from '../../context/UserContext';

const LoginForm = ({setUserState, setStep, phone, setPhone, firstName, setFirstName}) => {

       const [firstNameError, setFirstNameError] = useState(false);
    const [firstNameErrorText, setFirstNameErrorText] = useState("")

    const [phoneError, setPhoneError] = useState(false);
    const [phoneErrorMessage, setPhoneErrorMessage] = useState("")

    const GreenCheckbox = withStyles({
    root: {
        color: '#65ccb8',
        '&$checked': {
            color: '#65ccb8',
        },
    },
    checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const [checked, setChecked] = useState(true);

    const [spinner, setSpinner] = useState(false);

    const {setUserData} = useContext(UserContext);

      const partialSignup = async() => {

    await axios.post(`${url}/customer/checkout/guest`, 
      {
        first_name: firstName,
        phone: phone
      }
    ).then(res => {
      setStep(1)
    })
    .catch(err => {
      console.log(err.response)
    })

  }


    const handleSubmit = async(e) => {
        e.preventDefault();

        setSpinner(true);

        if(!firstName) {
            setFirstNameError(true)
            setFirstNameErrorText("First name cannot be blank.")
            setSpinner(false)

            return;

        }

        let testPhone = /^(?:\W*\d){12}\W*$/.test(phone)

        if(!testPhone || !firstName) {
            setPhoneError(true)
            setPhoneErrorMessage("Please enter a valid phone number")
            setSpinner(false)

            return;
        }

        setUserData({
            phone: phone
        })

        let data = {
            phone: phone,
            guest: true,
        }


        await axios.post(`${url}/customer/authentication/validate`, {
          phone: phone
        })
        .then(res => {

            if(res.data.new_user) {
                partialSignup();
            } else {
                setUserState(res.data);
            }


        })
        .catch(err => {

            console.log(err.response, "error validate user signup");
         
        })


                    
    }

    // first name cleanup

    useEffect(() => {

        if(!firstName && firstNameError) {

            setFirstNameError(true)

        } else {
            setFirstNameError(false)
            setFirstNameErrorText("")
        }

    }, [firstName, firstNameError])

    // phone cleanup

     useEffect(() => {

        setPhoneError(false);

        if( phoneError ) {

            setPhoneError(true)

        } else {
            setPhoneError(false)
            setPhoneErrorMessage("")
        }

    }, [phone, phoneError])



    return(

                <Form className="auth-form">
                    <FormGroup>
                    <FormGroup>
                        <TextField 
                        value={firstName}
                         error={firstNameError ? true : false}
                        helperText={firstNameErrorText}
                        
                         onChange={(e) => setFirstName(e.target.value)}
                         type="text" size="small" className="form-input" id={firstNameError ? "outlined-error-helper-text" : "outlined"}  label="First Name" variant="outlined" />
                    </FormGroup>
                    <PhoneInput
  country={'gb'}
  value={phone}
  placeholder="+44 7770257891"

countryCodeEditable={false}
enableSearch={true}

isValid={(value, country) => {


        // let intTest = /^\+(?:[0-9] ?){6,14}[0-9]$/.test(value)

        // console.log(value, "int value")
        // console.log(intTest, "int regex")



        var editedNumberLength = (country.dialCode).length
        var removedCode = value.slice(editedNumberLength);

        var number = removedCode;


        if(country.dialCode === "44" && number.substring(0, 1) === "0") {
        let numberNoZero = number.substring(1, )
         let testPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(numberNoZero)
            
            setPhone(country.dialCode + numberNoZero)
        }

            return true

    //   } else {
    //         let testPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(number)
    //         console.log(number, 'number')
    //         console.log(testPhone, "test phone without 0")
    //         setPhone(country.dialCode + number)

    //   }

       


   
  }}
  className="form-input"
  onChange={phone => setPhone(phone)}
   label="Phone" variant="outlined"
/>
                        {/* <TextField value={phone}
                     
                         error={phoneError ? true : false}
                        helperText={phoneErrorMessage}
                         InputProps={{
            startAdornment: <InputAdornment position="start">+44</InputAdornment>,
          }}
                         type="tel" size="small" className="form-input" onChange={(e) => {
                             
                             setPhone(e.target.value)
                             
                        

                             }} id={phoneError ? "outlined-error-helper-text" : "outlined"} label="Phone" variant="outlined" /> */}

                             {
                                 phoneError ?

                                 <small style={{color: 'red'}}>Please enter a valid phone number</small>

                                 :

                                    null
                             }
                    </FormGroup>
                
                    <FormGroup>
                        <Label check>
                            <GreenCheckbox checked={checked} onChange = {() => {setChecked(!checked)}}/>{' '}
                            <small>I agree to the <a style={{color: "#65ccb8"}} target="__blank" href="https://vizopay.com/terms">Terms & Conditions</a>.</small>
                        </Label>
                        
                    </FormGroup>
                    {/* <FormGroup>
                        <small>Already have an account? <a onClick={(e) => {
                            e.preventDefault();
                            setAuthMethod('Login');
                        }}>Login</a></small>
                    </FormGroup> */}

                    <FormGroup>
                         <Button onClick={handleSubmit} variant="contained" className="auth-button" disableElevation>
                         {
                             spinner ?

                            <CircularProgress className="spinner"/>

                             :

                             "Continue"

                         }
      
    </Button>
                    </FormGroup>
                </Form>


    )

}

export default LoginForm;