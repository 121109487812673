import React, {useEffect, useState} from 'react'

import axios from 'axios';
import url from '../../api/url';


function QRPaymentComplete() {

    var url_string = window.location.href
    var url1 = new URL(url_string);
    var payment_id = url1.searchParams.get("payment_id");

    const [socketId, setSocketId] = useState('');

    useEffect(() => {
        
        const getSocketId = async() => {
            await axios.post(`${url}/customer/socket/data/get`, {
                simp_id: payment_id
            }).then(res => {

                 setSocketId(res.data.socket_id)
                
            }).catch(err => {
                console.log(err.response);
            })
        }

        getSocketId();

    }, []);

    useEffect(() => {

        const checkCompletion = async() => {

               axios({
                        method: "POST",
                        url: `${url}/payment/direct/get/payment`,
                        data: {
                        simp_id: payment_id
                    }
                }).then(res => {
                axios.post(`${url}/payment/qr`, {
                    qr: true,
                    complete: true,
                    redirect_url: `${res.data.redirect_url}`,
                    socket_id: socketId
                })
            }).catch(err => { 
                console.log(err.response);
            })
            
        }

        checkCompletion();
        
    },[socketId])

    return (
        <div>
            Payment complete! You can close this browser and finish the rest where you were. 
        </div>
    )
}

export default QRPaymentComplete
