import React, {useEffect, useState, useContext} from 'react'
import {Spinner} from 'reactstrap';

import axios from 'axios';
import Cookies from 'js-cookie';
import url from '../../api/url';

import QRContext from '../../context/QRContext';
import BankContext from '../../context/BankContext';


const PayQR = () => {


    const {QR, setQR} = useContext(QRContext);
    const [message, setMessage] = useState("Please wait...")

    var url_string = window.location.href
    var current_url = new URL(url_string);

    const token = current_url.searchParams.get("token");
    const payment_session_id = current_url.searchParams.get("payment_session_id")
    const provider_id=current_url.searchParams.get("provider_id");


    // Send QR code message to server.

    useEffect(() => {
        
        const sendQR = async() => {
            await axios.post(`${url}/customer/socket/data/get`, {
                payment_session_id: payment_session_id
            }).then(res => {

                axios.post(`${url}/payment/qr`, {
                qr: true,
                complete: false,
                redirect_url: null,
                socket_id: res.data.socket_id
            }).then(res => {
            }).catch(err => {
                console.log(err)
            })
                
            }).catch(err => {
                console.log(err.response);
            })
             
        }

        sendQR();
    }, [])


    useEffect(() => {

        let app = (new URL(window.location.href)).searchParams.get('app')

        const createPaymentIntent = async() => {

        await axios.post(`${url}/payment/direct/create-payment`, {
         payment_session_id: payment_session_id
        }, {
            headers: {
                "x-auth-token": token
            }
        }).then(res => {
          
        }).catch(err => console.log(err.response));

        }


        const getAccessToken = async() => {

            await axios.post(`${url}/payment/direct/access-token`, {
                payment_session_id: payment_session_id,
            }, 
            {
                headers: {
                    'x-auth-token': token
                }    
            }).then(res => {

                axios.post(`${url}/payment/direct/initiate`, {
                    payment_session_id: payment_session_id,
                    provider_id: provider_id
                }, {
                    headers: {
                        'x-auth-token': token,
                        Authorization: res.data.access_token,
                        'redirect_uri': "https://vizopay-gateway.herokuapp.com/complete/qr"
                    }
                }).then(response => {
                    window.location.assign(`${response.data.message.auth_uri}`)
                }).catch(err => {
                    console.log(err.response.data);
                })

            }).catch(err => {
                console.log(err.response)
            });
        }

        if(app) {

            createPaymentIntent().then(() =>getAccessToken())

        } else {
        getAccessToken();
            
        }

        
    }, [])

    return (
        <div>
            <Spinner />
            <span>{message}</span>
        </div>
    )
}

export default PayQR
