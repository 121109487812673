import React, {useState, useEffect} from 'react'
import {Spinner, Row, Col} from 'reactstrap';

import socketIOClient from "socket.io-client";

import './qr.css'

import { QRCode } from 'react-qrcode-logo';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';


import url from '../../api/url';
import axios from 'axios';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CircularProgress from '@material-ui/core/CircularProgress';

import Instagram from './Images/instagram.png'
import Twitter from './Images/twitter.png'
import Facebook from './Images/facebook.png'
import Linkedin from './Images/linkedin.png'

 
function QRScreen() {

    const [steps, setSteps] = useState([
    {
        icon: 1,
        color: "#65ccb8",
        visible: true,
        loading: true,
        description: "Scan the QR Code with your camera phone.",
    },
    {
        icon: 2,
        color: "#65ccb8",
        visible: false,
        loading: true,
        description: "Complete the payment on your phone.",
    },
    {
        icon: 3,
        color: "#65ccb8",
        visible: false,
        loading: false,
        description: "Plant and Track your impact in the App. ",
    },
])

    var url_string = window.location.href
    var current_url = new URL(url_string);

    let amount = current_url.searchParams.get("amount")
    let payment_session_id = current_url.searchParams.get("payment_session_id")
    let store_image = current_url.searchParams.get("image_url")
    let store_name = current_url.searchParams.get("store_name")


    const [loading, setLoading] = useState(true)
    const [paymentLoader, setPaymentLoader] = useState(false);

    const [qrSocketId, setQRSocketId] = useState("payment_session_id");

    const [qrURL, setQRURL] = useState("");

    const [items, setItems] = useState([
        {
            item: "T Shirt",
            price: 1999,
            quantity: "2"
        },
        {
            item: "LV Handbag",
            price: 128399,
            quantity: "1"
        }
    ])


      // Socket


    useEffect(() => {
        
        const socket = socketIOClient(`${url}`, {
            path: "/qr/socket",
            withCredentials: true,
            transportOptions: {
            polling: {
                extraHeaders: {
                    "my-custom-header": "abcd"
                }
            }
        }
    });

    socket.on('connect', () => {

        setQRSocketId(socket.id)
    })

      socket.on('message', (data) => {

          console.log("DATA FROM SOCKET: ", data)

        if(data.status) {
            
                let array = [...steps]
            switch(data.status) {
                    

                case "active":

                      setPaymentLoader(true)

     
            array[0].loading = false
            array[1].visible = true
            setSteps(array)

                    break;

                case "payment":

            array[0].loading = false
            array[1].visible = true
            setSteps(array)
                    
                    break;

                case "complete":

                         
            array[1].loading = false
            array[2].visible = true
            setSteps(array)
                    setPaymentLoader(false)
                    // setSuccessScreen(true)

                break;

            }

        }

      
      }) 
    }, [])

    useEffect(() => {

       const getOrderDetails = async() => {
            await axios.post(`${url}/payment/online/order-details`, {
            payment_session_id: payment_session_id
        })
        .then(response => {
            setItems(response.data.response.line_items)
        })
        .catch(err => {
            console.log(err, "ERROR FETCHING ORDER DETAILS")
        })
       }

       getOrderDetails()

    }, [])

    useEffect(() => {

        if(!qrSocketId) {
            return;
        } else {

            const CreateQRLink = async() => {
                await axios.post(`${url}/payment/online/generate-qr`, {
                online_qr_socket: qrSocketId,
                payment_session_id: payment_session_id,
            }).then(response => {
                setQRURL(response.data.url)
                setLoading(false)
            })
            .catch(err => {
                console.log(err.response);
            })
            }

            CreateQRLink()

        }

    }, [qrSocketId])

        const socials = [
        {
            image: Instagram,
            tag: "@vizopay",
            link: "https://instagram.com/vizopay"
        },
        {
            image: Twitter,
            tag: "@vizopayofficial",
            link: "https://twitter.com/vizopayofficial"
        },
        {
            image: Facebook,
            tag: "@vizopay",
            link: "https://facebook.com/vizopay"
        },
        {
            image: Linkedin,
            tag: "@vizopay",
            link: "https://linkedin.com/company/vizopay"
        }
    ]

    return (
                <div className="checkout-container"> 
                <div className="checkout-content-container">
            {
                loading ?

                <>
                <div className="pay-link-wrapper">
                <Spinner style={{width: '8rem', height: '8rem', borderWidth: "10px", color: "#fff"}} />
            </div>
            <div className="pay-link-wrapper">
                <h2 style={{color: "#fff"}}>Please wait...</h2>
            </div>
                
                </>

                :

                <Row style={{height: '100%'}}>
                <Col style={{height: '100%', width: '100%', paddingRight: 0}}>
                    <div className="left-side">
                    <div style={{width:'100%'}}>
                        <div className="floating-back-button">
                            <KeyboardBackspaceIcon />
                        </div>
                        <div style={{ height: 200, width: '100%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage:`url(https://server.vizopay.com/${store_image})`}}>
                        </div>
                        
                    </div>
                    <div className="payment-info">
                        <h5 style={{color: 'grey', marginTop: 10, marginBottom: 10}}>Payment to {store_name}</h5>
                        <h2 style={{marginTop: 10, marginBottom: 10}}>£{(amount/100).toFixed(2)}</h2>
                    </div>
                    <div className="receipt">
                          <h4>Summary</h4>
                            <ul className="receipt-list-container">
                                {
                                    items.map(item => {
                                        return(
                                            <li className="receipt-list-item">
                                    <span>{item.quantity}x {item.item}</span>
                                    <div>
                                        <span  style={{paddingLeft: 5, paddingRight: 5}}>£{(item.price/100).toFixed(2)}</span>
                                        <span style={{paddingLeft: 5, paddingRight: 5, fontSize: '0.8rem', color: '#65ccb8'}}>9.12 kg CO2</span>
                                    </div>

                                </li>
                                        )
                                    })
                                }
                               
                               
                              
                  
                            </ul>
                    </div>
                </div>
                </Col>
                <Col style={{height: '100%', width: '100%', paddingLeft: 0}}>
                <div className="right-side">
                       <div style={{width: '90%', alignSelf: 'center', padding: 20, flexGrow: 1}}>
                            <h5>Please scan the QR code with your camera phone</h5>
                       </div>
                    <div className="qr-scan-container">
                        <QRCode eyeRadius={[
	{ // top/left eye
		outer: [10, 10, 0, 10],
		inner: [0, 10, 10, 10],
	},
	[10, 10, 10, 0], // top/right eye
	[10, 0, 10, 10], // bottom/left
]} size={220} qrStyle="dots"  value={qrURL} />
                    </div>
                    <div style={{flexGrow: 4}} className="status-container">
                        <ul style={{listStyle: 'none', padding: 0, width: '100%', textAlign: 'left'}}>
                            {
                                steps.map((step) => {
                                    
                                        if(step.visible) {
                                            return(
                                            <li style={{display: 'flex', alignItems: 'center', marginTop: 10, marginBottom: 10}}>
                                                {
                                                    step.loading ?

                                                     <CircularProgress className="spinner-green"/>

                                                    :

                                                      <CheckCircleIcon className="icon-check"/>
                                                    
                                                }
                                                <span style={{fontSize: '1rem', fontWeight: '600', marginLeft: 10}}>{step.description}</span>
                                            </li>
                                            )

                                        } else {
                                            return;
                                        }
                                })
                            }
                        </ul>
                    </div>
                     <div style={{

                    display: "flex",
                    flexGrow: 3,
                    justifyContent: "center",
                    alignItems: "center",
                    width: '100%'
                }}> 
                    {
                        socials.map((social) => {
                            return(
                                   <Col>
                                        <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column'
                                    }}>
                                <a href={social.link} target="__blank">
                                        <img height={40} width={40} src={social.image}/>
                                </a>
                                    {/* <span className="social-media-icon">{social.tag}</span> */}
                                    </div>
                                   </Col>
                            )
                        })
                    }
                </div>
                </div>
                </Col>
                </Row>

                /* <>
                {
                paymentLoader ?

                <>
                <div className="pay-link-wrapper">
                <Spinner style={{width: '8rem', height: '7rem', borderWidth: "10px", color: "#fff"}} />
            </div>
            <div className="pay-link-wrapper">
                <h2 style={{color: "#fff"}}>Please complete the payment on your phone...</h2>
            </div>
                </>

                :

                <>
                <div className="pay-link-wrapper">
                <QRCode style={{padding: '10px'}} eyeRadius={[
	[10, 10, 0, 10],
	[10, 10, 10, 0], 
	[10, 0, 10, 10], 
]} size={250} qrStyle="dots"  value={qrURL} />
            </div>
            <div className="pay-link-wrapper">
                <h1 style={{fontWeight: '700'}}>Pay £{(amount/100).toFixed(2)}</h1>
            </div>
                </>

            }

            <div className="container content-space-2 content-space-lg-3">
                 <ul className="step step-md step-centered">
        
                {
                    steps.map((step) => {
                        return(
                            <li className="step-item"> 
          <div  className="step-content-wrapper">
            <span style={{backgroundColor: ` ${step.color} !important`}} className="step-icon step-icon-color">{step.icon}</span>
            <div className="step-content">
              <h6>{step.description}</h6>

            </div>
          </div>
        </li>
                        )
                    })
                }
      </ul>
            </div>
                </> */
            }
            </div>
        </div>
    )
}

export default QRScreen
