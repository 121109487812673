import React, {useEffect, useState} from 'react'
import url from '../../api/url';
import axios from 'axios';
import Cookies from 'js-cookie';

import Lottie from 'react-lottie';
import PaymentCompleteAnimation from '../lottie/PaymentComplete.json'
import PaymentBlockedAnimation from '../lottie/PaymentCancel.json'

import AppleDownload from '../../Images/black.svg';
import AndroidDownload from '../../Images/google-play-badge.png';

import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = () => {
    return( 
        <div>
                <CircularProgress style={{color: "#65ccb8"}}/>
                <br />
                <small>Hold tight... we're just making sure that payment was sustainable.</small>
            </div>
    )
}

const PaymentSuccess = ({payment, payment_intent_id}) => {

        const [stopped, setStopped] = useState(false);
    const [paused, setPaused] = useState(false)

      const defaultOptions = {
      loop: false,
      autoplay: true, 
      animationData: PaymentCompleteAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      } 
    };

    return(
            <div>
                <Lottie options={defaultOptions}
              height={120}
              width={120}
                style={{margin: '10px auto'}}
              />
              <p>Your payment was successful!</p>
              
            <div style={{display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column", margin: "50px auto"}}>
                <p><span style={{fontWeight: "700"}}>Amount</span>: £{(payment.amount/100).toFixed(2)}</p>
                {
                    payment_intent_id ?

                    null

                    :

                    <p><span style={{fontWeight: "700"}}>Reference</span>: {payment.reference}</p>
                }
            </div>
            <div>
                <h6>Track your impact:</h6>
                <div
                    style={
                        {
                            display: 'flex',
                            flexDirection: 'column',
                            paddingTop: 10,
                            
                        }
                    }
                >
                    <span style={{marginTop: 10, marginBottom: 5}}>iOS:</span>
                <a href="https://apple.co/3pw6hVT">
                    <img src={AppleDownload} height={50}/>
                </a>

                <span style={{marginTop: 10, marginBottom: 5}}>Android (coming soon):</span>
                <a href="#">
                    <img style={{opacity: 0.6}} src={AndroidDownload} height={68}/>
                </a>
                </div>
                
            </div>
            </div>
    )
}

const PaymentBlocked = () => {

        const [stopped, setStopped] = useState(false);
    const [paused, setPaused] = useState(false)

      const defaultOptions = {
      loop: false,
      autoplay: true, 
      animationData: PaymentBlockedAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      } 
    };

    return(
            <div>
                <Lottie options={defaultOptions}
              height={120}
              width={120}
                style={{margin: '10px auto'}}
              />
              <br />
              <small>Oh no! Your payment was unsuccessful. Please try again.</small>

            </div>
    )
}

function PaymentComplete() {

    var url_string = window.location.href
    var url1 = new URL(url_string);
    var payment_id = url1.searchParams.get("payment_id");
    var payment_intent_id = url1.searchParams.get("payment_intent_id");

    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(true);
    const [blocked, setBlocked] = useState(false)

    const [payment, setPayment] = useState({});

    const [tryAgain, setTryAgain] = useState(false)

     useEffect(() => {

        const checkCompletion = async() => { 

            setTryAgain(false);

            const data = payment_intent_id ? {
                payment_intent_id: payment_intent_id
            } : {
                    simp_id: payment_id
                } 

            await axios({
                method: "POST",
                url: `${url}/payment/direct/get/payment`,
                data: data
            }).then(res => { 
                 

                 setPayment(res.data)

                if(payment_intent_id) {

                    if(res.data.status === "succeeded") {
                        setSuccess(true)
                    } else {
                     setBlocked(true)

                    }

                } else {
                     if(res.data.status === "executed") {
                     setSuccess(true);
                 } else if(res.data.status === "cancelled") {
                     setBlocked(true)
                 } else {
                    setTryAgain(true)
                 }
                }
            }).catch(err => {

                console.log(err.response);
            })
        }

        checkCompletion(); 

     }, [tryAgain])

    return (
        <div style={{height: '100%', width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div className="payment-container">
            <div className="confirm-pay-container">
                {
                    
                    success ? 

                    <PaymentSuccess payment={payment} payment_intent_id={payment_intent_id} />


                    :

                    blocked ?

                    <PaymentBlocked />

                    :


                    <Loader />




                }
            </div>
        </div>
        </div>
    )
}

export default PaymentComplete
