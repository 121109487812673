import React, {useState, useEffect, useContext} from 'react'
import {Container, Row, Col, Card, CardBody, CardTitle, CardText, Button} from 'reactstrap';
import {Link} from 'react-router-dom';
import CheckoutContext from '../../context/CheckoutContext';
import axios from 'axios';
import url from '../../api/url';

const Basket = ({amount, originalPrice}) => {    

    const {checkoutData, setCheckoutData} = useContext(CheckoutContext);

    const [ogPrice, setOgPrice] = useState(1299)
    const [price, setPrice] = useState(ogPrice)
    const [q, setQ] = useState(1)


    useEffect(() => {

        setPrice(q*ogPrice)

        

    }, [q])

    const handleSubmit = async(e) => {
        e.preventDefault();

       await axios.post("http://localhost:8080/basket-demo", {
           amount: price,
           item_price: ogPrice,
           quantity: q
       }).then(res => {
           console.log(res.data)
       }).catch(err => {
           console.log(err)
       })

    }

    return (
        <div>
            <Card style={{padding: '40px'}}>
                <CardBody>
                    <CardTitle>T-Shirt</CardTitle>
                    <CardText>Price: £{(price/100).toFixed(2)}</CardText>
                    <CardText>{q}</CardText>
                    <Row>
                        <Col><Button onClick={() => {
                            if(q === 0) {
                                setQ(0);
                            } else {
                                setQ(q - 1)
                            }
                        }}>-</Button></Col>
                        <Col><Button onClick={() => {
                            if(q === 10 || q > 10) {
                                setQ(10)
                            } else {
                                setQ(q + 1)
                            }
                        }}>+</Button></Col>
                    </Row>
                    <div className="checkout-button-container">

                    <Row>
                         <Link to="/checkout"><Button onClick={handleSubmit}>Checkout </Button></Link>
                    </Row>
                    </div>
                </CardBody>
            </Card>
               
            
        </div>
    )
}

export default Basket
