import React, {useState, useEffect} from 'react'

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import AddCard from './AddCard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Cardlayout from './Cardlayout';
import { Spinner } from 'reactstrap';
import Cookies from 'js-cookie';
import axios from 'axios';
import url from '../../api/url';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress } from '@mui/material';
import StripeAddCard from '../stripe/StripeAddCard';
import { Elements } from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';





const stripePromise = loadStripe('pk_live_51HIjc2IZ27b5E9790OgsgjnBQWfh6Z3WqfwbJRwVQVF0a4v6WG59BjzJEzQnLhpxKjEnSibsL6afRQNP8pxMRZUO00jCVK67YL');



function SelectPaymentMethod({paymentMethod, setPaymentMethod, handleClick, obData,  paymentMethodId, setPaymentMethodId }) {

    const [debitCard, setDebitCard] = useState(false)
    const [open, setOpen] = React.useState(false);

    const selectPaymentMethod = (value) => {

        setPaymentMethod(value)

        localStorage.setItem("paymentMethod", value)
    }   

    const [cardData,setCardData] = useState([]);
    const [cardLoading,setCardLoading] = useState(true)


    useEffect(() => {

        const getCardData = async() => {
    
          let token = Cookies.get('token');
    
          await axios.get(`${url}/customer/stripe/get-payment-method`, {
            headers: {
              'x-auth-token': token
            }
          }).then(res => {
           setCardData(res.data.paymentMethods.data)
           
           setCardLoading(false)
          }).catch(err => {
            toast.error(err.response.data.message, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                });
            setCardLoading(false)
          });
    
        }
    
        getCardData();
    
      }, [open])



    return (
        <>
        <ToastContainer/>
         <FormControl component="fieldset">
      <FormLabel   component="legend">Please select a Payment Method</FormLabel>
      <RadioGroup
        aria-label="payment type"
        defaultValue={paymentMethod}
        name="radio-buttons-group"
        onChange={(e) => selectPaymentMethod(e.target.value)}
      >
        {/* <div className="payment-method-select-container">
            <FormControlLabel value="bank" control={<Radio />} label="Pay by Bank" />
            {
                paymentMethod === "bank" ?

                obData.set ?

                <div style={{height: '100%'}}>
                <a style={{marginRight: 5, cursor: 'pointer'}} onClick={handleClick}>Change Bank</a>
                <img width={20} src={obData.bank_data.logo} />
                </div>

                :

                <a style={{cursor: 'pointer'}} onClick={handleClick}>Select Bank</a>


                :

                null
            }
        </div> */}
        <div className="payment-method-select-container">

        <FormControlLabel value="wallet" control={<Radio />} label="Apple Pay / Google Pay" />
        </div>
        <div className="payment-method-select-container">

        <FormControlLabel value="card" control={<Radio />} label="Debit / Credit Card" />
        {
            paymentMethod === "card" ?

            <div>
                 <AddCircleIcon onClick={() => setOpen(true)} style={{cursor: 'pointer', color: "#65ccb8"}} /> 
            </div>

            :

           null


        }
        </div>
      </RadioGroup>
      {
            paymentMethod === "card" ?


    
          <>            
           <div className="change-payment-container">
          <h6>Saved Cards</h6>

            </div>
              {
                  cardData.length > 0 ?
                      cardData?.map((data) => {

                          return(

                          
                          <div onClick={() => setPaymentMethodId(data.id)} className="saved-payments-container" style={{borderLeft: paymentMethodId===data.id ? "6px solid #65ccb8" : "1px solid transparent", borderRight: paymentMethodId===data.id ? "1px solid #65ccb8" : "1px solid transparent", borderTop: paymentMethodId===data.id ? "1px solid #65ccb8" : "1px solid transparent", borderBottom: paymentMethodId===data.id ? "1px solid #65ccb8" : "1px solid #e3e8ee"}}>
                                 
                          {/* <FormControlLabel value={data.id} control={<Radio />} label={data.card.brand} /> */}
                             <Cardlayout  data={data} />     
                           </div>
                       ) })
                  :
                    cardLoading? 
                    <Spinner style={{alignSelf: "center", justifySelf: "center", color: "#65ccb8"}} />
                    :
                  <div>
                     Add a Card 
                  </div>
              }

              </>
            :

           null


        }

    </FormControl>
    <Elements stripe={stripePromise}>
    <StripeAddCard open={open} setOpen={setOpen}/>
    </Elements>
    </>
    )
}

export default SelectPaymentMethod
