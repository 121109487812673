import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './components/stripe/CheckoutForm';
import axios from 'axios';

import url from './api/url';
import Cookies from 'js-cookie'
import WalletPay from './components/stripe/WalletPay';
import { CircularProgress } from '@material-ui/core';


// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_51HIjc2IZ27b5E9790OgsgjnBQWfh6Z3WqfwbJRwVQVF0a4v6WG59BjzJEzQnLhpxKjEnSibsL6afRQNP8pxMRZUO00jCVK67YL');

const StripeCheckout = ({paymentMethodId, setPaymentMethodId, paymentSessionId, paymentMethod, checkoutData, setConfirmPayment, setImpactSection, setMerchantLoader}) => {

    const [clientSecret, setClientSecret] = useState("");

    const [loading, setLoading] = useState(true);


    var url_string = window.location.href
var curr_url = new URL(url_string);
var online = curr_url.searchParams.get("online");
var payment_session_id = curr_url.searchParams.get("payment_session_id")

   useEffect(() => {
        const fetchSecret = async() => {
            await axios.post(`${url}/payment/stripe/create-intent`, {
        amount: 1299,
        payment_session_id: online ? payment_session_id : paymentSessionId
    }, {
      headers: {
        'x-auth-token': Cookies.get('token')
      }
    })
        .then(res => {

            setClientSecret(res.data.client_secret)
            setLoading(false)
        })
        .catch(err => {
            console.log(err.response)
        })
        }

        fetchSecret()
   }, [])

          const options = {
    // passing the client secret obtained in step 2
    clientSecret: clientSecret,
  };

  return (
    <div style={{display: 'flex', backgroundColor: '#fff', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
        {
          !clientSecret ?

          <div className="StripeElement">
                <div class="apple-pay-button-black">
                <CircularProgress size="1.1rem" color="#fff"/>
                </div>
            </div>

          :
        <Elements stripe={stripePromise}  options={options}>

        {
          paymentMethod==="wallet" ?

          <WalletPay setMerchantLoader={setMerchantLoader} setConfirmPayment={setConfirmPayment} setImpactSection={setImpactSection} checkoutData={checkoutData} clientSecret={clientSecret}/>

          :

          <CheckoutForm setMerchantLoader={setMerchantLoader} setConfirmPayment={setConfirmPayment} setImpactSection={setImpactSection} paymentMethodId={paymentMethodId} setPaymentMethodId={setPaymentMethodId} checkoutData={checkoutData} paymentMethod={paymentMethod} clientSecret={clientSecret}/>

        }
      
    </Elements>
      }
    </div>
  );
};

export default StripeCheckout;