import React, {useContext, useEffect, useState} from 'react'
import {Container, Row, Col} from 'reactstrap';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import BankContext from '../../context/BankContext';

import axios from 'axios';
import Cookies from 'js-cookie';
import url from '../../api/url';
import SelectPaymentMethod from './SelectPaymentMethod';

const ImpactSection = ({paymentMethod, setPaymentMethod , paymentMethodId , setPaymentMethodId,kgCo2,carbonTreeRatio }) => {

    const checkoutData = {
        price: Number((new URL(window.location.href)).searchParams.get('amount')),
        store_name: (new URL(window.location.href)).searchParams.get('store_name')
    }

    const [donationFee, setDonationFee] = useState(0);

     

    const [amountSet, setAmountSet] = useState(false);

    const trees = (checkoutData.price/100)*donationFee/0.1;
    const carbon = (trees*12.3).toPrecision(4)

    const {obData, setObData} = useContext(BankContext);

    const handleClick = () => {
        setObData({
            set: false,
            changed: true
        })
    }

    useEffect(() => { 

        const url_string = window.location.href;
        const url1 = new URL(url_string)
        const payment_session_id = url1.searchParams.get('payment_session_id');

        const getDonationFee = async(req, res) => {
            await axios.post(`${url}/payment/donation`, {
                   
                    payment_session_id: payment_session_id
                
            }).then(res => {
                setDonationFee(res.data.donation_fee)
            }).catch(err => {
                console.log(err.response);
            })
        }

        getDonationFee();

    }, []);

    return (
        <>
          
            {
                amountSet ?

                <Row>
                <div className="list-impact-div">
                    <div className="amount-header">
                        <h1>£{(checkoutData.price/100).toFixed(2)}</h1>
                        <h5>Payment to: {checkoutData.store_name}</h5>
                    </div>
                    <div>
                    <ul>
                        
                        {
                            ((checkoutData.price/100)*0.01) < 0.1 ? 

                        null


                            :

                            

                                Math.floor(trees) > 0.1 ?

                                <li><CheckCircleIcon className="icon-check"/> Plant {Math.floor(trees)} {
                            Math.floor(trees) > 1 ? 'trees with your purchase.' : 'tree with your purchase.'
                        }</li>

                            :

                            null

                            
                        

                        }
                        
                        <li><CheckCircleIcon className="icon-check"/> Earn {Math.floor((kgCo2/carbonTreeRatio)*100)} seed credits with your purchase.</li>
                        <li><CheckCircleIcon className="icon-check"/>Remove up to {kgCo2.toFixed(2)}kg of CO<sub>2</sub> <span className="data-param">/per year</span></li>
                    </ul>
                    </div>
                </div>
            </Row>

                :

                null


            }
            {
                obData.set ?
             
                    <div className="impact-bank-container">
                        {/* <Row>
                            <Col sm="4" className="flex-center-right">
                                    <div className="impact-bank-image">
                                        <img src={obData.bank_data.logo} />
                                    </div>   
                            </Col>
                            <Col sm="7" className="flex-center-left">
                                <div className="right-container">
                                    <Row style={{margin: "15px auto"}}>
                                        <span>Paying from {obData.bank_data.bank}</span>
                                    </Row>
                                    <Row style={{margin: "15px auto"}}>
                                        <a onClick={handleClick} href="#">Change account</a>
                                    </Row>
                                </div>
                                
                            </Col>
                        </Row> */}
                        <SelectPaymentMethod paymentMethod={paymentMethod}  paymentMethodId = {paymentMethodId} setPaymentMethodId = {setPaymentMethodId} setPaymentMethod={setPaymentMethod} obData={obData} handleClick={handleClick}/>
                    </div>
               

                : 

                <Row>
                <small className="business-donate-hook"><strong>Vizopay Store</strong> will donate {donationFee*100}% of this transaction to help combat climate change.</small>
            </Row>
            }
        </>
    )
}

export default ImpactSection
