import React, {useState, useEffect} from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@material-ui/core/TextField';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import Cookies from 'js-cookie';
import url from '../../api/url';
import {FormGroup, Row, Col} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "95%",
  maxWidth: "500px",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};




function AddCard({open, setOpen}) {


    const [cardNumber, setCardNumber] = useState(""); 
    const [cardNumberMask, setCardNumberMask] = useState("")
    const [expiryMonth,setExpiryMonth] = useState("")
    
    const [expiryYear, setExpiryYear] = useState("");
    const [postCode,setPostCode] = useState("");
    const [cvc, setCvc] = useState("");
    const [expiry,setExpiry] = useState("")
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [name,setName] = useState("");

        const addCard = async () =>{
            await axios.post(`${url}/customer/stripe/save-payment-method`,{
            number: cardNumber,
            exp_month: expiry.slice(0,2),
            exp_year: "20" + expiry.slice(3),
            cvc: cvc,
            full_name:name,
            postal_code: postCode
            },
            {
              headers: {
                'x-auth-token': Cookies.get('token')
              }
            })
                .then(res => {
                    toast.success("Card Added",{
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,  
                    })

                    
                    handleClose();
                })
                .catch(err => {
                    toast.error(err.response.data.message, {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        });

                        handleClose();
                })


            
        }

/*
    useEffect(() =>{
        if (expiry.length ===2) {
            setExpiry(expiry + "/")
        }
    }, [expiry])
*/
    const enterCardNumber = (value) => {

        setCardNumber(value);

        let cardMask = value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
        setCardNumberMask(cardMask);

    }



    
    const ColorButton = styled(Button)(({ theme }) => ({
        color: "white",
        backgroundColor: "#65ccb8",
        '&:hover': {
          backgroundColor: "#65ccb8",
        },
      }));


    return (
        <div>
            <ToastContainer />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
            <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add a Debit Card or Credit Card
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
         
              <FormGroup>
                        <TextField  
                        required
                fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={cardNumberMask}            
                         onChange={(e) => enterCardNumber(e.target.value)}
                         type="text" size="small" className="form-input" id={"outlined"}  label="Card number" inputProps={{ maxLength: 19 }} variant="outlined" name="ccnumber"  id="ccnumber" autocomplete="cc-number"   placeholder="4000 0000 0000 0000"/>
                    </FormGroup>
                    <FormGroup>
                        <TextField  
                        required
                fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={name}            
                         onChange={(e) => setName(e.target.value)}
                         type="text" size="small" className="form-input" id={"outlined"}  label="Name on Card"  variant="outlined"  placeholder="Name on Card" id="nameoncard" name="nameoncard"  autocomplete="cc-name"                        />
                    </FormGroup>
                <Row>
                    <Col>
                        <FormGroup>
                             <TextField  
                                required
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }}
                                keyboardType={'numeric'}
                                value={expiry}            
                                onChange={(e) => setExpiry(e.target.value)}
                                type="text" size="small" className="form-input" id={"outlined"} inputProps={{ maxLength: 5}}  label="Expiry" placeholder="MM / YY" variant="outlined" name="cc-exp" autocomplete='cc-exp' id= 'cc-exp'

                            />
                        </FormGroup>
                    </Col>
                    <Col>
                    <FormGroup>
                             <TextField  
                                required
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }}
                                value={cvc}            
                                onChange={(e) => setCvc(e.target.value)}
                                type="text" size="small" className="form-input" id={"outlined"}  label="CVC" placeholder="CVC" variant="outlined"  name="cvc" id="cvv2" autocomplete="cc-csc"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                             <TextField  
                                required
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }}
                                value={postCode}            
                                onChange={(e) => setPostCode(e.target.value)}
                                type="text" size="small" className="form-input" id={"outlined"}  label="Post Code" placeholder="E17 4DS" variant="outlined" 
                            />
                        </FormGroup>
                    </Col>    
                </Row>
                <Row > 
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems:"center",
                        width: "100%"
                    }}>
                    <ColorButton style={{width: "80%"}} variant="contained" onClick={addCard} >
                    Add Card
                </ColorButton>
                    </div>
                </Row>
          </Typography>
        </Box>
    
      </Modal>
    </div>
    )
}

export default AddCard
