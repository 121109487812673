import React, {useState, useEffect, useMemo} from 'react'
import {PaymentRequestButtonElement, useStripe, useElements} from '@stripe/react-stripe-js';
import './stripe.css';

import {Redirect} from 'react-router-dom'
import { CircularProgress, Button } from '@material-ui/core';

function WalletPay({paymentMethod, checkoutData, clientSecret, setImpactSection, setMerchantLoader, setConfirmPayment}) {

      const stripe = useStripe();
  const elements = useElements(); 

  const [paymentRequest, setPaymentRequest] = useState(null);

  const [redirectUrl, setRedirectUrl] = useState(false);
  const [redirectParams, setRedirectParams] = useState("")

  const [noWalletAvailable, setNoWalletAvailable] = useState(false);

    useEffect(() => {

    if (stripe && elements ) {
      const pr = stripe.paymentRequest({
        country: 'GB',
        currency: 'gbp',
        total: {
          label: "Demo (WON'T BE CHARGED)",
          amount: checkoutData.price,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

        pr.canMakePayment().then(result => {

             if(!result) {
               console.log(result)
               setNoWalletAvailable(true)
             }

        if (result) {
          setPaymentRequest(pr);
        }
      });

      pr.on('paymentmethod', async (ev) => {
  // Confirm the PaymentIntent without handling potential next actions (yet).
  const {paymentIntent, error: confirmError} = await stripe.confirmCardPayment(
    clientSecret,
    {payment_method: ev.paymentMethod.id},
    {handleActions: false}
  );

  if (confirmError) {
    // Report to the browser that the payment failed, prompting it to
    // re-show the payment interface, or show an error message and close
    // the payment interface.
    ev.complete('fail');
    console.log("ev: ", ev)
  } else {
    // Report to the browser that the confirmation was successful, prompting
    // it to close the browser payment method collection interface.
    ev.complete('success');

    if(paymentIntent.status === "succeeded") {
         setRedirectParams(`?amount=${paymentIntent.amount}&payment_intent_id=${paymentIntent.id}`)
        setRedirectUrl(true)
    }
    // Check if the PaymentIntent requires any actions and if so let Stripe.js
    // handle the flow. If using an API version older than "2019-02-11"
    // instead check for: `paymentIntent.status === "requires_source_action"`.
    if (paymentIntent.status === "requires_action") {
      // Let Stripe.js handle the rest of the payment flow.
      const {error} = await stripe.confirmCardPayment(clientSecret);
      if (error) {
        // The payment failed -- ask your customer for a new payment method.
      } else {
        // The payment has succeeded

          setRedirectParams(`?amount=${paymentIntent.amount}&payment_intent_id=${paymentIntent.id}`)
        setRedirectUrl(true)
      }
    } else {
      // The payment has succeeded.
    }
  }
});

    }
  }, [stripe, elements]);
  if(!paymentRequest) {
      return(
                
                  noWalletAvailable ?
                  <div style={{maxWidth: '500px', width: '90%'}}>
                    <Button onClick={() => {
                    setImpactSection(true)
                    setConfirmPayment(false)
                    setMerchantLoader(false)
                  }} variant="contained" className="auth-button" disableElevation>
                    Change Payment Method
                  </Button>
                  <small style={{fontSize: "0.7rem"}}>Oops... That payment method isn't available on your device. Please select another payment method.</small>
                  </div>

                  :
            <div className="StripeElement">

                  
                  

                  <div class="apple-pay-button-black">
                <CircularProgress size="1.1rem" color="#fff"/>
                </div>
            </div>
                
   )
  } else {
return (
        <div style={{width: '100%'}}>
            {
                redirectUrl ?

                 <Redirect
  to={{
    pathname: "/status",
    search: redirectParams,
  }}
/>

:

            <>
            <PaymentRequestButtonElement options={{paymentRequest}}/>
            
           
            </>
            }
        </div>
    )
  }
    
}

export default WalletPay
