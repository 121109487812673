import React, {useState, useContext, useEffect} from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import url from '../../api/url';
import axios from 'axios';
import Cookies from 'js-cookie';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
 
import UserContext from '../../context/UserContext';

const SignupForm = ({step, setStep, isAuthenticated, setIsAuthenticated, setAuthMethod, setVerifyCode, phone, setPhone}) => {

    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState("")

    const [passwordError, setPasswordError] = useState(false)
    const [passwordText, setPasswordText] = useState("")

    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordText, setConfirmPasswordText] = useState("")

    


    const GreenCheckbox = withStyles({
    root: {
        color: '#65ccb8',
        '&$checked': {
            color: '#65ccb8',
        },
    },
    checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const [checked, setChecked] = useState(true);

    const [spinner, setSpinner] = useState(false);

    const {setUserData} = useContext(UserContext);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleSubmit = async(e) => {
        e.preventDefault();

        setSpinner(true);

         const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let emailTest = re.test(String(email).toLowerCase());

        if(!emailTest) {
            setSpinner(false)
            setEmailError(true);
            setEmailErrorText("Please enter a valid email address.")

            return;
        }

        var passwordTest = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

        if(!passwordTest.test(password) || password.length <= 6) {
            setSpinner(false)
            setPasswordError(true)
            setPasswordText("Password must contain at least 6 characters, a number and a special character.")

            return;
        }

        if(password !== confirmPassword) {
            setSpinner(false)
            setConfirmPasswordError(true)
            setConfirmPasswordText("Passwords do not match.")

            return;
        }


        let data = {
            phone: phone,
            email: email,
            password: password
        }

        await axios.post(`${url}/customer/checkout/guest/signup`, data)
                    .then(res => {
                        if(res.status === 200) {
            
                            Cookies.set('token', res.data.token)
                            setSpinner(false);

                            setIsAuthenticated(true)
                        }
                    }).catch(err => {
                        console.log(err.response)
                        setSpinner(false);
                    });

    }

    // email validation cleanup

    useEffect(() => {

        if(emailError) {
                     const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let emailTest = re.test(String(email).toLowerCase());

        if(!emailTest) { 
            setEmailError(true)
        } else {
            setEmailError(false)
            setEmailErrorText("")
        }
        } else {
            setEmailError(false)
            setEmailErrorText("")
        }

    }, [email, emailError])

    // password validation cleanup

    useEffect(() => {

        var passwordTest = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

        if(passwordError) {

            if(!passwordTest.test(password)) {
                setPasswordError(true)
            } else {
                setPasswordError(false);
                setPasswordText("")
            }

        } else {
            setPasswordError(false)
            setPasswordText("")
        }

    }, [password, passwordError])

    // confirm password validation cleanup

        useEffect(() => {

        var passwordTest = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

        if(confirmPasswordError) {

            if(password !== confirmPassword) {
                setConfirmPasswordError(true)
            } else {
                setConfirmPasswordError(false);
                setConfirmPasswordText("")
            }

        } else {
            setConfirmPasswordError(false)
            setConfirmPasswordText("")
        }

    }, [confirmPassword, confirmPasswordError])



    return(

                <Form className="auth-form">
                    <FormGroup>
                        <TextField 
                        error={emailError ? true : false}
                        helperText={emailErrorText}
                         onChange={(e) => setEmail(e.target.value)}
                         type="text" size="small" className="form-input" id={emailError ? "outlined-error-helper-text" : "outlined"} label="Email" variant="outlined" />
                    </FormGroup>
                    <FormGroup>
                        <TextField 
                        error={passwordError ? true : false}
                        helperText={passwordText}
                         onChange={(e) => setPassword(e.target.value)}
                         type="password" size="small" className="form-input" id={passwordError ? "outlined-error-helper-text" : "outlined"} label="Password" variant="outlined" />
                    </FormGroup>
                    <FormGroup>
                        <TextField 
                        error={confirmPasswordError ? true : false}
                        helperText={confirmPasswordText}
                         onChange={(e) => setConfirmPassword(e.target.value)}
                         type="password" size="small" className="form-input" id={confirmPasswordError ? "outlined-error-helper-text" : "outlined"} label="Confirm Password" variant="outlined" />
                    </FormGroup>
                    
                    
                

                    <FormGroup>
                         <Button onClick={handleSubmit} variant="contained" className="auth-button" disableElevation>
                         {
                             spinner ?

                            <CircularProgress className="spinner"/>

                             :

                             "Continue"

                         }
      
    </Button>
                    </FormGroup>
                </Form>


    )

}

export default SignupForm;