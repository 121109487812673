import React, {useState, useContext, useEffect} from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import url from '../../api/url';
import axios from 'axios';
import Cookies from 'js-cookie';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
 
import UserContext from '../../context/UserContext';

const EnterPassword = ({step, setStep, isAuthenticated, setIsAuthenticated, setAuthMethod, setVerifyCode, phone, setPhone}) => {


        const [phoneError, setPhoneError] = useState(false);
    const [phoneErrorMessage, setPhoneErrorMessage] = useState("")

    const [userError, setUserError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    

    const GreenCheckbox = withStyles({
    root: {
        color: '#65ccb8',
        '&$checked': {
            color: '#65ccb8',
        },
    },
    checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const [checked, setChecked] = useState(true);

    const [spinner, setSpinner] = useState(false);

    const {setUserData} = useContext(UserContext);

    const [password, setPassword] = useState("");

    const handleSubmit = async(e) => {
        e.preventDefault();

        setSpinner(true);

        let data = {
            phone: phone,
            password: password
        }

        if(!password) {
            setSpinner(false)
            setUserError(true)
            setErrorMessage("Password cannot be blank")

            return
        }

        await axios.post(`${url}/customer/login`, data)
                    .then(res => {
                        if(res.status === 200) {
            
                            Cookies.set('token', res.data.token)
                            setSpinner(false);

                            setIsAuthenticated(true)
                        }
                    }).catch(err => {
                        console.log(err.response)
                        setSpinner(false);
                        if(err.response.status == 500) {
                            setUserError(true)
                            setErrorMessage("Oops something has gone wrong on our end. Sorry about this.")
                        } else {
                            setUserError(true)
                            setErrorMessage("Incorrect phone number or password.")
                        }
                    });

    }

    // password validation cleanup

    useEffect(() => {

        if (password.length > 0 && userError) {
            setUserError(false)
        } else {
            return;
        }

    }, [password])


    return(

                <Form className="auth-form">
                   
                   <FormGroup>
                       <TextField value={phone}
                     
                         error={phoneError ? true : false}
                        helperText={phoneErrorMessage}
                         InputProps={{
            startAdornment: <InputAdornment position="start">+</InputAdornment>,
          }}
                         type="tel" size="small" className="form-input" onChange={(e) => {
                             
                             setPhone(e.target.value)
                             
                             }} id={phoneError ? "outlined-error-helper-text" : "outlined"} label="Phone" variant="outlined" />
                   </FormGroup>
                    <FormGroup>
                        <TextField 
                         onChange={(e) => setPassword(e.target.value)}
                         type="password" size="small" className="form-input" id="outlined" label="Password" variant="outlined" />
                    </FormGroup>
                    
                    
                    
                

                    <FormGroup>
                         <Button onClick={handleSubmit} variant="contained" className="auth-button" disableElevation>
                         {
                             spinner ?

                            <CircularProgress className="spinner"/>

                             :

                             "Continue"

                         }
      
    </Button>
                    </FormGroup>
                    {
                        userError ?

                        <small style={{color: '#FF5C58'}}>{errorMessage}</small>

                        :

                        null
                    }
                </Form>


    )

}

export default EnterPassword;