import React, {useEffect, useState} from 'react'

import Lottie from 'react-lottie';
import PaymentBlockedAnimation from '../lottie/PaymentCancel.json'

function PaymentExpired() {

      const defaultOptions = {
      loop: false,
      autoplay: true, 
      animationData: PaymentBlockedAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      } 
    };

    return (
            <div style={{height: '100%', width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div className="payment-container">
            <div className="confirm-pay-container">
                <Lottie options={defaultOptions}
              height={120}
              width={120}
                style={{margin: '10px auto'}}
              />
              <small>Oops... that payment session has expired! Please restart the payment.</small>
           
            </div>
             </div>
              </div>
    )
}

export default PaymentExpired
