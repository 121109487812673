import React from 'react'
import BankEntry from './BankEntry'

import {Col} from 'reactstrap'

function BankConstructor({data, setImpactSection}) {
    return (
        <Col xs={4}>
            <BankEntry 
                key={data.id}
                id={data.id}
                displayable_name={data.displayable_name}
                icon={data.icon}
                main_bg_color={data.main_bg_color}
                logo={data.logo}
                setImpactSection={setImpactSection}
            />
        </Col>
    )
}

export default BankConstructor
