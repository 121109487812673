import React, {useState} from 'react'
import Lottie from 'react-lottie';
import animationData from './lottie-qr-animation.json';

function ScanQRLottie() {

    const [stopped, setStopped] = useState(false);
    const [paused, setPaused] = useState(false)

      const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    const buttonStyle = {
      display: 'block',
      margin: '10px auto'
    };

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',

        },
        header: {
            fontSize: 20,
            fontFamily: "Avenir",
            fontWeight: '700',
            margin: '20px auto'

        },
        footer: {
            fontSize: 14,
            fontFamily: 'Avenir',
            fontWeight: '400',
            margin: '10px auto'
        }
    }

    return (

            <div >
            <span style={styles.header}>Pay by bank</span>
                <Lottie options={defaultOptions}
              height={120}
              width={120}
              isStopped={stopped}
              isPaused={paused}
                style={{margin: '10px auto'}}
              />
              <span style={styles.footer}>Scan the QR code with your camera phone. Click the link and pay. <strong>It's easy.</strong></span>

            </div>

    
    )
}

export default ScanQRLottie
