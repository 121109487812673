import React, {useState, useContext, useEffect} from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import url from '../../api/url';
import axios from 'axios';
import ReactCodeInput from 'react-verification-code-input';
import Cookies from 'js-cookie';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import InputAdornment from '@mui/material/InputAdornment';
 
import UserContext from '../../context/UserContext';

function VerifyCode({incrementStep, phone, setIsAuthenticated}) {

    const [code, setCode] = useState("")
    const [spinner, setSpinner] = useState(false)


    const [resend, setResend] = useState(false);
    const [timer, setTimer] = useState(59)

        // TIMER

    useEffect(() => {

        if(timer > 0 ) {
            setTimeout(() => setTimer(timer - 1), 1000)
        } else {
            setResend(true)
        }

    }, [timer])

    const [errorCode, setErrorCode] = useState(false)

    const handleSubmit = async() => {

        if(!code || code.length !== 4) {
            setErrorCode(true);
        }

        await axios.post(`${url}/customer/checkout/code`, {
            verification_code: code,
            phone: phone
        })
        .then(res => {
            

            Cookies.set("token", res.data.token)
            setIsAuthenticated(true)
        })
        .catch(err => {
            if(err.response.status===401) {
            setErrorCode(true)

            }
            console.log(err.response)
        })

    }

    return (
                <>
                {
                    timer===0 ?

                    <small>Resend code</small>

                    :

                <small style={{color: "#C8C6C6"}}>Send reset code in {timer>9 ? `00:${timer}` : `00:0${timer}`}</small>
                }
                <Form className="auth-form">
                    <FormGroup>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: "10px auto", width: '100% !important'}}>

                    <ReactCodeInput className="verify-code" fields={4} type="number" style={{borderColor: '#65ccb8 !important'}} onChange={(e) => {
                       
                        setCode(e)
                    }} />

                    </div>
                         
                    </FormGroup>

                    {
                        errorCode ?

                        <FormGroup style={{textAlign: 'center'}}>
                            <small style={{color: '#FF5C58'}}>You have entered the incorrect code.</small>
                        </FormGroup>

                        :

                        null

                    }

                    <FormGroup>
                         <Button onClick={handleSubmit} variant="contained" className="auth-button" disableElevation>
                         {
                             spinner ?

                            <CircularProgress className="spinner"/>

                             :

                             "Continue"

                         }
      
    </Button>
                    </FormGroup>
                </Form>
                </>
    )
}

export default VerifyCode
