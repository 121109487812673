import React from 'react'
import {Container, Row, Col, Spinner} from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';

function MerchantLoading() {
    
    return (
            <>
            <Row style={{margin: "10px auto"}}>
                <Col>

                    <Spinner className="qr-spinner"/>


                </Col>
            </Row>
            <Row style={{margin: "10px auto"}}>
                <Col>
                <span>Waiting for merchant...</span>

                </Col>
            </Row>
            </>
        
    )
}

export default MerchantLoading
