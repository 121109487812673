import React, {useState, useContext, useEffect} from 'react';
import CheckoutPage from './components/checkout/CheckoutPage';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from 'react-router-dom';

import Basket from './components/basket/Basket';

import CheckoutContext from './context/CheckoutContext';
import UserContext from './context/UserContext';
import QRContext from './context/QRContext';
import BankContext from './context/BankContext'

import NewLayout from './components/new-checkout/NewLayout';

import url from './api/url';
import Cookies from 'js-cookie';
import axios from 'axios'; 

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';


import './App.css';
import PayQR from './components/payqr/PayQR';
import PaymentComplete from './components/completed/PaymentComplete';
import QRPaymentComplete from './components/payqr/QRPaymentComplete';

import SocketId from './context/SocketId';
import CheckBankDetails from './functions/CheckBankDetails';

import AuthLayout from './components/authentication/AuthLayout.js';

import CircularProgress from '@material-ui/core/CircularProgress';
import QRScreen from './components/payqr/QRScreen';
import PaymentExpired from './components/expired/PaymentExpired';
import StripeCheckout from './StripeCheckout';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51HIjc2IZ27b5E9790OgsgjnBQWfh6Z3WqfwbJRwVQVF0a4v6WG59BjzJEzQnLhpxKjEnSibsL6afRQNP8pxMRZUO00jCVK67YL');


const theme = createMuiTheme({
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
});



function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const originalPrice = 12.99;
  const [price, setPrice] = useState(originalPrice)
  const [amount, setAmount] = useState(1)

  const [QR, setQR ] = useState(false);
  const [QRPath, setQRPath] = useState('');

  const [app, setApp] = useState(false);

  let vizopay_vendor_id = (new URL(window.location.href)).searchParams.get('vizopay_vendor_id');



  // CHECK TO SEE IF PAYMENT ORIGIN IS EPOS THE UPDATE STATUS

  useEffect(() => {

    let epos = (new URL(window.location.href)).searchParams.get('epos')
    let merchant_socket_id = (new URL(window.location.href)).searchParams.get('merchant_socket_id');

    if(epos) {
      
      const eposSocketUpdate = async() => {
        await axios.post(`${url}/payment/terminal/socket`, {
        status: "started",
        merchant_socket_id: merchant_socket_id
      }).then()
        .catch(err => console.log(err.response))
      }

      eposSocketUpdate();

    }

    return;


  }, [])

  const [paymentExpired, setPaymentExpired] = useState(false)


    useEffect(() => {

    let online = (new URL(window.location.href)).searchParams.get('online')


    let payment_session_id = (new URL(window.location.href)).searchParams.get('payment_session_id');

 
      const UpdateQR = async() => {
        await axios.post(`${url}/payment/online/active-qr`, {
          payment_session_id: payment_session_id
        })
        .then(res => {


        })
        .catch(err => {
          console.log(err, "QR active error response");

          if(err.response.status === 401) {
            setPaymentExpired(true)
          }
        })
      }

      if(online) {

        UpdateQR()
      } else {
        return;
      }  


  }, [])

  // CHECK TO SEE IF USER HAS SCANNED WITH APP

  useEffect(() => {

    let origin_app = (new URL(window.location.href)).searchParams.get('app')
    let user_token = (new URL(window.location.href)).searchParams.get('user_token')
    let payment_session_id = (new URL(window.location.href)).searchParams.get('payment_session_id')
    let provider_id = (new URL(window.location.href)).searchParams.get('provider_id');
    let merchant_socket_id = (new URL(window.location.href)).searchParams.get('merchant_socket_id');

    if(origin_app) {

      Cookies.set('token', user_token);

      console.log(app);

      setQRPath(`payment_session_id=${payment_session_id}&token=${user_token}&provider_id=${provider_id}&app=true&merchant_socket_id=${merchant_socket_id}`)

      setApp(true) 

      
      return <Redirect to={QRPath}/>

    } 

  }, [])
  
  const [checkoutData, setCheckoutData] = useState({
    quantity: 0,
    vizopay_id: vizopay_vendor_id,
    client_secret: '',
    redirect: false,
    redirectURL: false
  })



  const [userData, setUserData] = useState({
    email: ""
  })

  const [obData, setObData] = useState({
    bank_data: {},
    set: true,
    changed: false
  });

  const [bankSet, setBankSet] = useState(false);
  

  useEffect(() => {

  if(!app) {
      const token = Cookies.get('token');


    const getData = async() => {
      await axios.get(`${url}/customer/payment/data`, {
        headers: {
          'x-auth-token': token
        }
      }).then(res => {

        setUserData({
          email: res.data.email
        })
      }).catch(err => console.log(err));
    }

    getData();
  } 

  }, [app, isAuthenticated])


  const [spinner, setSpinner ] = useState(true);

    useEffect(() => {

    

    const checkAuthentication = async() => {

      let token = Cookies.get('token');

      await axios.get(`${url}/customer/authenticate`, {
        headers: {
          'x-auth-token': token
        }
      }).then(res => {
        if(res.status === 200) {
          setIsAuthenticated(true);
          setSpinner(false);
        } else {
          setIsAuthenticated(false); 
          setSpinner(false);
        }
      }).catch(err => {
        console.log(err.response)
        setSpinner(false);
      });

    }

    checkAuthentication();

  }, [])





  const [socketId, setSocketId] = useState('');


    const options = {
    // passing the client secret obtained in step 2
    clientSecret: 'sk_test_51HIjc2IZ27b5E979SqhmPp6898AEBZcEfjWc50F8wveLsUuepop5Cn7EK1SVRFUOkxI5FiSuykeUfwXTNSvEXnMu00KoIHFpey',
    // Fully customizable with appearance API.
    appearance: {/*...*/},
  };

  return (
    <Router>
    <MuiThemeProvider theme={theme}>
    <CheckoutContext.Provider value={{checkoutData, setCheckoutData}}>
    <UserContext.Provider value={{userData, setUserData}}>
    <QRContext.Provider value={{QR, setQR}}>
    <BankContext.Provider value={{obData, setObData}}>
    <SocketId.Provider value={{socketId, setSocketId}} >
    <div className="App">
        <div className="outer-container">
         {
           spinner ?

        <CircularProgress className="page-loading-spinner" />


           :

            <Switch>
              {
                checkoutData.redirectURL ? window.location.replace(checkoutData.successURL)

                :
                <>
              {
                paymentExpired ?

                <Redirect to="/expired" />

                :

                app ?

                <Redirect to={`/pay/qr/?${QRPath}`}/>

                :

                <>
                <Route exact amount={amount} path="/">
                
          {/* <Elements stripe={stripePromise} options={options}>

                <NewLayout isAuthenticated={isAuthenticated}/>
                </Elements> */}

                      {
        isAuthenticated ?

                <CheckoutPage isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}/>

                :

                <AuthLayout isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}/>

                      }

              </Route>
              <Route exact path="/payment">
                  {
        isAuthenticated ?

                <CheckoutPage isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}/>

                :

                <AuthLayout isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}/>

                      }
              </Route>
              </>
              }
  
              <Route exact path="/checkout" component={Basket}/>
              <Route exact path={`/pay/qr`} component={PayQR}/>
              <Route exact path="/status" component={PaymentComplete}/>
              <Route exact path="/complete/qr" component={QRPaymentComplete} />
              <Route exact path="/qr" component={QRScreen}/>
              <Route exact path="/expired" component={PaymentExpired}/>
              <Route exact path="/stripe-checkout" component={StripeCheckout}/>
                </>
              }
 
          </Switch>
         }
        </div>      
    </div>
    </SocketId.Provider>
    </BankContext.Provider>
    </QRContext.Provider>
    </UserContext.Provider>
    </CheckoutContext.Provider>
    </MuiThemeProvider>
    </Router>
    

  );
}

export default App;
